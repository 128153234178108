import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <Navbar
      className="d-flex justify-content-between align-items-center"
      color="dark"
      dark
    >
      <NavbarBrand>
        <img
          alt="logo"
          src={logo}
          style={{
            height: 50,
          }}
        />
      </NavbarBrand>
      {/* <NavbarBrand>CPV ARABIA</NavbarBrand> */}
    </Navbar>
  );
};

export default Header;
