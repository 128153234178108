import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Input, Table } from "reactstrap";

const RatingComponent = ({
  comments,
  rating,
  setRating,
  inputValues,
  setInputValues,
  errors,
  showErrors,
}) => {
  // console.log("inputValues", inputValues);
  const ratingHandler = (rate, id) => {
    const ratingObjects = rating.map((item) => {
      if (item.id === id) {
        return { ...item, value: rate };
      }
      return item;
    });

    setRating(ratingObjects);
  };

  const changeTextHandler = (id, value) => {
    const textObjects = inputValues.map((item) => {
      if (item.id === id) {
        return { ...item, value };
      }
      return item;
    });

    setInputValues(textObjects);
  };

  const changeValueHandler = (values, setValues, value, id) => {
    const objectsArray = values.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });

    setValues(objectsArray);
  };

  const fillColorArray = [
    "#f17a45",
    "#f19745",
    "#f1a545",
    "#f1b345",
    "#f1d045",
  ];

  const ratingComments = comments.filter((comment) => comment.Type === "Radio");
  const textComments = comments.filter((comment) =>
    ["Text", "TextArea", "Phone", "Email"].includes(comment.Type)
  );
  // const textComments = comments.filter((comment) => comment.Type === "Text");
  // console.log("textComments", textComments);
  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "1px 1px 10px #888",
        overflow: "hidden",
      }}
      className="px-3 mb-4"
    >
      {ratingComments.length > 0 && (
        <Table className="project-list-table table-nowrap align-middle table-borderless">
          <thead>
            <tr style={{ borderBottom: "1px solid gray" }}>
              <th></th>
              <th className="text-center" style={{ width: "20px" }}>
                Rating
              </th>
            </tr>
          </thead>
          <tbody>
            {ratingComments.map((item, i) => (
              <tr key={i} style={{ borderBottom: "1px solid gray" }}>
                <td>
                  <p className="my-0">{item.QTitleEN}</p>
                  <p className="my-0">{item.QTitleAR}</p>
                </td>
                <td>
                  <Rating
                    size={23}
                    // onClick={(rate) => ratingHandler(rate, item.QID)}
                    onClick={(rate) =>
                      changeValueHandler(rating, setRating, rate, item.QID)
                    }
                    transition
                    fillColorArray={fillColorArray}
                  />
                  {showErrors && errors[`rating-${item.QID}`] ? (
                    <span
                      style={{ fontSize: "14px" }}
                      className="text-danger d-flex justify-content-end"
                    >
                      {errors[`rating-${item.QID}`]}
                    </span>
                  ) : null}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {textComments.length > 0 && (
        <>
          {textComments.map((item, i) => (
            <div key={i} className="mb-3">
              <p className="my-0">{item.QTitleEN}</p>
              <p className="my-0">{item.QTitleAR}</p>
              {/* {item.Type === "Text" ? (
                <Input
                  className="mt-3"
                  type="text"
                  name="text"
                  id="exampleText"
                  value={
                    inputValues.filter((input) => input.id === item.QID)[0]
                      ?.value || ""
                  }
                  onChange={(e) => {
                    console.log("e", e.target.value, item.QID);
                    changeValueHandler(
                      inputValues,
                      setInputValues,
                      e.target.value,
                      item.QID
                    );
                  }}
                  // onChange={(e) => changeTextHandler(item.QID, e.target.value)}
                />
              ) : item.Type === "TextArea" ? (
                <Input
                  className="mt-3"
                  type="textarea"
                  name="text"
                  id="exampleText"
                  value={
                    inputValues.filter((input) => input.id === item.QID)[0]
                      ?.value || ""
                  }
                  onChange={(e) =>
                    changeValueHandler(
                      inputValues,
                      setInputValues,
                      e.target.value,
                      item.QID
                    )
                  }
                  // onChange={(e) => changeTextHandler(item.QID, e.target.value)}
                />
              ) : item.Type === "Phone" ? (
                <Input
                  className="mt-3"
                  type="tel"
                  name="tel"
                  id="exampleText"
                  value={
                    inputValues.filter((input) => input.id === item.QID)[0]
                      ?.value || ""
                  }
                  onChange={(e) =>
                    changeValueHandler(
                      inputValues,
                      setInputValues,
                      e.target.value,
                      item.QID
                    )
                  }
                  // onChange={(e) => changeTextHandler(item.QID, e.target.value)}
                />
              ) : item.Type === "Email" ? (
                <Input
                  className="mt-3"
                  type="email"
                  name="email"
                  id="exampleEmail"
                  value={
                    inputValues.filter((input) => input.id === item.QID)[0]
                      ?.value || ""
                  }
                  onChange={(e) =>
                    changeValueHandler(
                      inputValues,
                      setInputValues,
                      e.target.value,
                      item.QID
                    )
                  }
                  // onChange={(e) => changeTextHandler(item.QID, e.target.value)}
                />
              ) : (
                ""
              )} */}

              <Input
                className="mt-3"
                // type="email"
                type={
                  item.Type === Text
                    ? "text"
                    : item.Type === "TextArea"
                    ? "textarea"
                    : item.Type === "Email"
                    ? "email"
                    : item.Type === "Phone"
                    ? "tel"
                    : ""
                }
                name={item.Type}
                id={`item.Type-${item.QID}`}
                value={
                  inputValues.filter((input) => input.id === item.QID)[0]
                    ?.value || ""
                }
                onChange={(e) =>
                  changeValueHandler(
                    inputValues,
                    setInputValues,
                    e.target.value,
                    item.QID
                  )
                }
                // onChange={(e) => changeTextHandler(item.QID, e.target.value)}
              />
              {showErrors && errors[`comment-${item.QID}`] ? (
                <span
                  style={{ fontSize: "14px" }}
                  className="text-danger d-flex justify-content-end"
                >
                  {errors[`comment-${item.QID}`]}
                </span>
              ) : null}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default RatingComponent;
