import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Row } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

import ProjectInfo from "../components/ProjectInfo";
import RatingComponent from "../components/RatingComponent";
import AdditionalComments from "../components/AdditionalComments";
import Header from "../components/Header";
import ErrorComponent from "../components/ErrorComponent";
import LoadingComponent from "../components/LoadingComponent";
import ResponseComponent from "../components/ResponseComponent";

const Home = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("CSF");
  //   console.log("token", token);

  const [comments, setComments] = useState([]);
  const [additionalComments, setAdditionalComments] = useState([]);
  const [rating, setRating] = useState([]);
  const [limit, setLimit] = useState();
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [inputValues, setInputValues] = useState([]);
  const [additional, setAdditional] = useState(false);
  const [response, setResponse] = useState();
  const [showErrors, setShowErrors] = useState(false);

  // console.log("additional", additional);
  // console.log("inputValues", inputValues);
  // console.log("comments", comments);
  // console.log("rating", rating);

  // **************************Fetching Data *******************
  useEffect(() => {
    axios
      .get("https://admin.cpvarabia.com/Care/show_care_all.php")
      .then((res) => {
        // console.log("res", res);

        let array = Object.values(res.data);
        array.splice(-1);

        let allComments = array.filter((item) => item.AVG === "0");

        let ratingComments = allComments.filter(
          (item) => item.Type === "Radio"
        );

        let additionalCommentsArray = array.filter((item) => item.AVG === "1");

        let initialRating = ratingComments.map((item) => {
          return {
            id: item.QID,
            value: 0,
            required: item.Required === "1" ? true : false,
          };
        });

        setRating(initialRating);
        setComments(allComments);
        setAdditionalComments(additionalCommentsArray);

        const initialInputValues = array
          // .filter((item) => item.AVG === "1" || item.Type === "Text")
          .filter(
            (item) =>
              item.AVG === "1" ||
              ["Text", "TextArea", "Phone", "Email"].includes(item.Type)
          )
          .map((item) => {
            return {
              id: item.QID,
              value: "",
              required: item.Required === "1" ? true : false,
              AVG: item.AVG,
              type: item.Type,
            };
          });
        setInputValues(initialInputValues);
      })
      .catch((err) => console.log("err", err));

    axios
      .post("https://admin.cpvarabia.com/Care/Care.php", { Token: token })
      .then((res) => {
        // console.log("res", res.data);

        if (res.data.error === false) {
          setLimit(res.data.AVGLimit);
          let array = Object.values(res.data);
          array.splice(-2);
          setData(array[0]);
        } else {
          setErrorMessage(res.data.message);
        }
      })
      .catch((err) => console.log("err", err));
  }, [token]);

  //**********Error validation */
  const errors = {};
  for (let i = 0; i < rating.length; i++) {
    if (rating[i].value === 0 && rating[i].required) {
      errors[`rating-${rating[i].id}`] = "required";
    } else {
      delete errors[`rating-${rating[i].id}`];
    }
  }

  for (let i = 0; i < inputValues.length; i++) {
    if (inputValues[i].AVG === "0") {
      if (!inputValues[i].value && inputValues[i].required) {
        errors[`comment-${inputValues[i].id}`] = "required";
      } else {
        delete errors[`comment-${inputValues[i].id}`];
      }
    }

    if (inputValues[i].type === "Email" && inputValues[i].value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(inputValues[i].value))
        errors[`comment-${inputValues[i].id}`] = "invalid";
    }

    if (inputValues[i].type === "Phone" && inputValues[i].value) {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(inputValues[i].value))
        errors[`comment-${inputValues[i].id}`] = "invalid";
    }
  }

  if (additional) {
    for (let i = 0; i < inputValues.length; i++) {
      if (inputValues[i].AVG === "1") {
        if (!inputValues[i].value) {
          errors[`comment-${inputValues[i].id}`] = "required";
        } else {
          delete errors[`comment-${inputValues[i].id}`];
        }
      }
    }
  }

  // ****************** Show Additional Comments ****************
  useEffect(() => {
    let ratingValue = 0;
    for (let i = 0; i < rating.length; i++) {
      const ratingType = comments.filter(
        (comment) => comment.QID === rating[i].id
      )[0]?.Type;

      if (ratingType === "Radio") {
        ratingValue = ratingValue + rating[i].value;
      }
    }

    // checking if there is rating with no answer
    const ratingValues = rating.filter(
      (rate) => rate.required === true && rate.value === 0
    );

    if (ratingValues.length === 0) {
      if (ratingValue < limit) {
        setAdditional(true);
      } else if (ratingValue >= limit) {
        setAdditional(false);
      }
    } else {
      setAdditional(false);
    }
  }, [comments, rating, limit]);

  //************** Submit Handler Function***************/
  const submitHandler = () => {
    setShowErrors(true);

    if (Object.values(errors).length === 0) {
      // for rating values
      let valuesArray = [...rating];

      //for other inputs values
      if (additional) {
        valuesArray = [...valuesArray, ...inputValues];
      } else {
        for (let i = 0; i < inputValues.length; i++) {
          if (inputValues[i].AVG === "0") {
            valuesArray = [...valuesArray, inputValues[i]];
          }
        }
      }

      const formData = { CareID: data?.CareID, rating: valuesArray };

      formData.rating = formData.rating.map((item) => ({
        QID: item.id,
        Answer: item.value,
      }));
      // console.log("formData", formData);

      axios
        .post("https://admin.cpvarabia.com/Care/AddCare.php", formData)
        .then((res) => {
          // console.log("res", res);
          setResponse({
            message: res.data.message,
            status:
              res.data.error === false
                ? "Success!"
                : res.data.error === true
                ? "Failed!"
                : "",
          });
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <React.Fragment>
      <Header />

      <div
        style={{
          width: "90%",
          margin: "auto",
          minHeight: "70vh",
        }}
        className="my-5"
      >
        <Row className="d-flex justify-content-center align-items-center">
          <Col
            md={8}
            lg={6}
            style={{ minHeight: "70vh" }}
            className="d-flex flex-column justify-content-center "
          >
            {response ? (
              <ResponseComponent response={response} />
            ) : token && !data && !errorMessage ? (
              <LoadingComponent type="spin" color="gray" />
            ) : !token ? (
              <ErrorComponent message={"No Token Provided!"} />
            ) : data ? (
              <>
                {data && <ProjectInfo data={data} />}

                {comments.length > 0 && (
                  <RatingComponent
                    comments={comments}
                    rating={rating}
                    setRating={setRating}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    errors={errors}
                    showErrors={showErrors}
                  />
                )}

                {additional && (
                  <AdditionalComments
                    additionalComments={additionalComments}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    errors={errors}
                  />
                )}

                {comments.length > 0 && (
                  <FormGroup className="d-flex ms-auto justify-content-end">
                    <Button
                      onClick={() => {
                        submitHandler();
                      }}
                      className="bg-primary"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                )}
              </>
            ) : errorMessage ? (
              <ErrorComponent message={errorMessage} />
            ) : null}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Home;
