import React from "react";

const ResponseComponent = ({ response }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "1px 1px 10px #888",
        overflow: "hidden",
      }}
      className="p-3 d-flex flex-column justify-content-center align-items-center"
    >
      <h1
        className={`${
          response.status === "Success!"
            ? "text-success"
            : response.status === "Failed!"
            ? "text-danger"
            : ""
        }`}
      >
        {response.status}
      </h1>
      <h3 className="text-center">{response.message}</h3>
    </div>
  );
};

export default ResponseComponent;
