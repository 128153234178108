import React from "react";

const ErrorComponent = ({ message }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "1px 1px 10px #888",
        overflow: "hidden",
      }}
      className="p-3 d-flex justify-content-center align-items-center"
    >
      <h3 className="text-center">{message}</h3>
    </div>
  );
};

export default ErrorComponent;
