import React from "react";
import { Input } from "reactstrap";

const AdditionalComments = ({
  additionalComments,
  inputValues,
  setInputValues,
  errors,
}) => {
  // console.log("inputValues", inputValues);
  // console.log("additionalComments", additionalComments);

  const changeTextHandler = (id, value) => {
    const textObjects = inputValues.map((item) => {
      if (item.id === id) {
        return { ...item, value };
      }
      return item;
    });

    setInputValues(textObjects);
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "1px 1px 10px #888",
        overflow: "hidden",
      }}
      className="mb-4 px-3 py-2"
    >
      {additionalComments.map((item, i) => (
        <div key={i} className="my-2">
          <p className="my-0">{item.QTitleEN}</p>
          <p className="my-0">{item.QTitleAR}</p>
          <Input
            className="mt-3"
            type="textarea"
            name="text"
            id="exampleText"
            value={
              inputValues.filter((input) => input.id === item.QID)[0]?.value ||
              ""
            }
            onChange={(e) => changeTextHandler(item.QID, e.target.value)}
          />
          {errors[`comment-${item.QID}`] ? (
            <span
              style={{ fontSize: "14px" }}
              className="text-danger d-flex justify-content-end"
            >
              {errors[`comment-${item.QID}`]}
            </span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default AdditionalComments;
