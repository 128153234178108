import React from "react";
import ReactLoading from "react-loading";

const LoadingComponent = ({ type, color }) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      //   style={{
      //     backgroundColor: "rgba(234, 240, 242, 0.3)",
      //     position: "absolute",
      //     width: "100%",
      //     height: "100%",
      //   }}
    >
      <ReactLoading type={type} color={color} height={30} width={30} />
    </div>
  );
};

export default LoadingComponent;
