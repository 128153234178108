import React from "react";
import { Col, Row, Table } from "reactstrap";

const ProjectInfo = ({ data }) => {
  return (
    <div
      style={{
        borderRadius: "10px",
        boxShadow: "1px 1px 10px #888",
        overflow: "hidden",
      }}
      className="mb-4 px-3 pt-1"
    >
      <Table className="project-list-table table-nowrap align-middle table-borderless">
        <tbody>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <th style={{ width: "120px" }}>Owner</th>
            <td>{data.OwnerName}</td>
          </tr>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <th>Contractor</th>
            <td>{data.ConstructorName}</td>
          </tr>

          <tr style={{ borderBottom: "1px solid gray" }}>
            <th>Reference No</th>
            <td>{data.ReferenceNo}</td>
          </tr>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <th>Inspector </th>
            <td>{data.UserName}</td>
          </tr>
          <tr style={{ borderBottom: "1px solid gray" }}>
            <th>Stage</th>
            <td>{data.StageName}</td>
          </tr>
          <tr>
            <th>Stage Date</th>
            <td>{data.CreatedAt}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ProjectInfo;
